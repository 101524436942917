import React, { useState, useEffect } from 'react';
import './Login.scss'
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import { Input } from '../../components/Input';
import i18n from '../../components/i18n';
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { Button, Row, Col } from 'react-bootstrap';
import { useGet } from '../../hooks/helper';
import * as Config from "../../config";

const Login = () => {
    const auth = useAuth();
    const { register, handleSubmit, errors } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState(false);
    const [userInfo, setUserInfo] = useState({ ip: '', country_short: '', country_long: '', city: '' });
    let history = useHistory();
    const get = useGet();


    useEffect(() => {
        const info = get.userInfo;
        if (info) {
            setUserInfo(get.userInfo);
        }
    }, [get.userInfo])

    useEffect(() => {
        if (auth.info) {
            if (auth.missingDetails()) {
                window.location.href = '/my-account/info';
            } else {
                window.location.href = '/platform/BTCUSD';
            }
        }
    }, [auth.info, history])

    const onSubmit = data => {
        setLoadingButton(true);
        data.info = userInfo ? JSON.stringify(userInfo) : '';
        auth.signIn(data);
        auth.onError.subscribe(x => {
            if (!x.status) {
                setLoadingButton(false);
                setError(true);
            }
        })
    }

    return (
        <>
            <div className="login thm-container sec-pad">
                <h1>{i18n.t('login.title')}</h1>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Input name="email" type="email" reference={register({ required: true, pattern: { value: /^[A-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i, message: "invalid email address" } })} errors={errors} title={i18n.t('login.email.label')} placeholder={i18n.t('login.email.placeholder')} />
                    <Input name="password" type="password" reference={register({ required: true })} errors={errors} title={i18n.t('login.password.label')} placeholder={i18n.t('login.password.placeholder')} />
                    <div>
                        <Button block className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">{i18n.t('login.button')}</Button>
                        <div className="error">{error && i18n.t('login.error_message')}</div>
                    </div>
                    <Row>
                        <Col><Link to='/password/request'>{i18n.t('login.forgot_password')}</Link></Col>
                        {!Config.ALLOW_REGISTRATION ? '' : <Col className="text-right">{i18n.t('login.new_here')} <Link to='/register'>{i18n.t('login.new_here_link')}</Link></Col>}
                    </Row>
                </form>
                <div className="reCAPTCHA">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</div>
            </div>
        </>
    );
}

export default Login;